import AWS from 'aws-sdk';

const emailTemplate = ({ name, email, phone, message }) => ({
  Destination: {
    /* required */
    ToAddresses: ["susan2ccs@yahoo.com"]
  },
  Message: {
    Body: {
      Text: {
        Charset: "UTF-8",
        Data: `
Someone has reached out to you via your website catering form.

Name: ${name}
Email: ${email}
Phone Number: ${phone}
Message: ${message}
`
      }
    },
    Subject: {
      Charset: "UTF-8",
      Data: `[Eight Mile Store] Catering Request`
    }
  },
  Source: "Fisherman Websites <info@gofisherman.com>",
  ReplyToAddresses: [email]
});

const subscriptionCallback = (fields) =>
  new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(emailTemplate(fields))
    .promise();

export default subscriptionCallback;